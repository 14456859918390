<template>
  <div>
    <div style="justify-content: left;margin-top: 20px;">
      <div style="width: 100vw;text-align: left;user-select: none;">数据库及工具:</div>
      <div style="text-align: left;margin-top: 10px;">
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="sql2008"
          v-clipboard:success="onCopySuccess">Sql Server2008</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="sql2005"
          v-clipboard:success="onCopySuccess">Sql Server2005</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="sqltxh"
          v-clipboard:success="onCopySuccess">Sql Server图形工具</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="sqltxhjr"
          v-clipboard:success="onCopySuccess">Sql Server向后兼容</el-button>
      </div>
    </div>
    <div style="justify-content: left;margin-top: 20px;">
      <div style="width: 100vw;text-align: left;user-select: none;">盖华软件:</div>
      <div style="text-align: left;margin-top: 10px;">
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="ghcy"
          v-clipboard:success="onCopySuccess">餐饮软件</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="ghbg"
          v-clipboard:success="onCopySuccess">宾馆软件</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="ghxy"
          v-clipboard:success="onCopySuccess">洗浴软件</el-button>
      </div>
    </div>
    <div style="justify-content: left;margin-top: 20px;">
      <div style="width: 100vw;text-align: left;user-select: none;">用易软件:</div>
      <div style="text-align: left;margin-top: 10px;">
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="yypj"
          v-clipboard:success="onCopySuccess">用易普及</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="yyhh"
          v-clipboard:success="onCopySuccess">用易豪华</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="yyts"
          v-clipboard:success="onCopySuccess">用易图书</el-button>
      </div>
    </div>
    <div style="justify-content: left;margin-top: 20px;">
      <div style="width: 100vw;text-align: left;user-select: none;">辛巴软件:</div>
      <div style="text-align: left;margin-top: 10px;">
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="xbdj"
          v-clipboard:success="onCopySuccess">辛巴单机</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="xbjy"
          v-clipboard:success="onCopySuccess">辛巴精英</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="xbqj"
          v-clipboard:success="onCopySuccess">辛巴旗舰</el-button>
      </div>
    </div>
    <div style="justify-content: left;margin-top: 20px;">
      <div style="width: 100vw;text-align: left;user-select: none;">其它工具:</div>
      <div style="text-align: left;margin-top: 10px;">
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="jzqd"
          v-clipboard:success="onCopySuccess">基站驱动</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="jbqd"
          v-clipboard:success="onCopySuccess">佳博驱动</el-button>
        <el-button type="primary" size="small" style="width: 150px;" v-clipboard:copy="sqlbak"
          v-clipboard:success="onCopySuccess">备份工具</el-button>
      </div>
    </div>
    <div>
      <el-dialog title="用户校验" :visible.sync="dialogFormVisible" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false">
        <el-form :model="form">
          <el-form-item label="校验码" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off" show-password></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="yz">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import clipboard from 'clipboard'
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      sql2008: 'http://jx3tool.cn/cn_sql_server_2008_r2_express_with_management_tools_x64.exe',
      sql2005: 'http://jx3tool.cn/SQLEXPR_CHS.EXE',
      sqltxh: 'http://jx3tool.cn/SQLServer2005_SSMSEE_x64.msi',
      sqlbak: 'http://jx3tool.cn/SqlServer%E5%A4%87%E4%BB%BD%E5%B7%A5%E5%85%B7.exe',
      jzqd: 'http://jx3tool.cn/USB-SERIAL%20CH340_3.5.2019.1_2020-12-30%2010%2030%2040.zip',
      jbqd: 'http://jx3tool.cn/GAINSCHA%20Receipt%20Printer%20Driver%20V20.zip',
      ghcy: 'http://jx3tool.cn/%E7%99%BE%E6%96%AF%E7%89%B9%E9%A4%90%E9%A5%AE%E7%B3%BB%E7%BB%9F%E5%AE%89%E8%A3%85%E5%8C%85.rar',
      ghbg: 'http://jx3tool.cn/%E7%99%BE%E6%96%AF%E7%89%B9%E5%AE%A2%E6%88%BF%E5%AE%89%E8%A3%85%E5%8C%85.rar',
      ghxy: 'http://jx3tool.cn/%E7%99%BE%E6%96%AF%E7%89%B9%E8%B6%B3%E6%B5%B4%E6%B4%97%E6%B5%B4%E5%AE%89%E8%A3%85%E5%8C%85.zip',
      yyhh: 'http://jx3tool.cn/%E7%94%A8%E6%98%93%E5%95%86%E8%B4%B8%E9%80%9A2019%E8%B1%AA%E5%8D%8E%E7%89%88SQL.rar',
      yypj: 'http://jx3tool.cn/%E7%94%A8%E6%98%93%E5%95%86%E8%B4%B8%E9%80%9A19%E6%99%AE%E5%8F%8A%E7%89%88SQL.rar',
      yyts: 'http://jx3tool.cn/%E7%94%A8%E6%98%93%E5%9B%BE%E4%B9%A6%E9%A6%86%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F2019%E8%B6%85%E5%BC%BA%E7%89%88SQL.rar',
      xbdj: 'http://jx3tool.cn/%E8%BE%9B%E5%B7%B4%E5%95%86%E8%B4%B8%E9%80%9A20230921.9.57.exe',
      xbjy: 'http://jx3tool.cn/%E8%BE%9B%E5%B7%B4%E5%95%86%E8%B4%B8%E4%BA%91%E7%B2%BE%E8%8B%B1%E7%89%882022.exe',
      xbqj: 'http://jx3tool.cn/%E8%BE%9B%E5%B7%B4%E5%95%86%E8%B4%B8%E4%BA%91%E6%97%97%E8%88%B0%E7%89%882022.exe',
      sqltxhjr:'https://cdn.bstsoftware.cn/%E8%BE%9B%E5%B7%B4%E6%95%B0%E6%8D%AE%E5%BA%93%E5%90%91%E5%90%8E%E5%85%BC%E5%AE%B9%E7%BB%84%E4%BB%B6%E5%B7%A5%E5%85%B7%EF%BC%88%E5%AE%89%E8%A3%85SQL2008R2%E6%97%B6%E5%BF%85%E8%A3%85%E7%BB%84%E4%BB%B6%EF%BC%89.rar',
      form: {
        name: ''
      },
      dialogFormVisible: true
    }
  },
  methods: {
    onCopySuccess() {
      this.$message({
        showClose: true,
        message: '已将下载链接复制到剪切板',
        type: 'success'
      });
    },
    yz() {
      if (this.form.name == '82827076') {
        this.dialogFormVisible = false
      } else {
        this.$message({
          showClose: true,
          message: '校验码错误，无法使用软件',
          type: 'error'
        });
      }
    }
  }
}
</script>
